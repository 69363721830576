import React, { useEffect, useState } from 'react'
import ReactToPrint from 'react-to-print'
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter'
import { Dialog, IconButton, Tooltip } from '@mui/material'
import CustomPagination from '../../../../Single Components/CustomPagination'
import { listWalletAPI } from '../GeneralAPI'
import { useSelector } from 'react-redux'
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import TableLoading from "../../../../Single Components/TableLoding"
import { Undo2Icon } from 'lucide-react'

const PosWallet = () => {
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Cash');
  const [paymentAmount, setPaymentAmount] = useState(15.00);

  const handleDataFromChild = (data) => {
    setFormDataInfo(data)

  };
  const list = useSelector((state) => state?.GeneralSlice?.walletListData)

  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };
  const hasData = list && list.length > 0;
  // const hasData = true;

  useEffect(() => {
    listWalletAPI(setIsLoading)
  }, [])
//dummy data for list 
const dummyData= [
  {
    date:"2024-01-01",
    sequence:"123456",
    orderRef:"123456",
    customer:"John Doe",
    branch:"Branch 1",
    shift:"Shift 1",
    employee:"Employee 1",
    paymentMethod:"Payment Method 1",
    points:100,
  }
]
//return dialog
const [open, setOpen] = useState(false);
const handleClose = () => setOpen(false);
//return click
  const handleReturnClick=(r)=>{
    console.log(r)
    setOpen(true)
  }

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
  };

  const handleReturn = () => {
    console.log('Return clicked');
    setOpen(false);
  };

  const handleCancel = () => {
    console.log('Cancel clicked');
    setOpen(false);
  };

  return (
    <div className='pos-conatiner' style={{ backgroundColor: "unset", padding: "0" }}>
      <div className="new-order-top-container" style={{ padding: "0", backgroundColor: "white", paddingInline: "10px" }}>
        <div className='new-order-top-left' >
          {/* <div className='new-order-top-left-home' onClick={homeBtnClickFn}>
          <HomeIcon/> 
        </div> */}
          <p className='new-order-top-left-p'>WALLET</p>
        </div>
        <div className="justify-center" style={{ gap: "10px", width: "70%", justifyContent: "flex-end" }}>
          <ReactToPrint
            trigger={() => (
              <IconButton className="printer-div" style={{ color: "#fff", backgroundColor: "#002995", borderRadius: "0" }}>
                <i class="bi bi-printer"></i>
              </IconButton>
            )}
          //   content={() => paymentsPrint}
          />
          <Tooltip title="Download">
            <IconButton style={{ backgroundColor: "#ffd2d2", color: "#002995", borderRadius: "0" }}>
              <i class="bi bi-arrow-down-circle"></i>
            </IconButton>
          </Tooltip>

          <CategorySearchandFilter
            statusOptions={[]}
            onData={handleDataFromChild}
            // isCreatedBy={}
            // isStatus={}
            onBranchSelect={handleBranchSelect}
            isSearch={true}
            isDate={false}
          //  isPos={true}
          />

        </div>
      </div>
      <div className="flex-column table-div-container" >
        <div className='pagi-div'>
          <CustomPagination
            currentPage={currentPage}
          // totalPages={totalPages}
          // onPageChange={handlePageChange}
          // setFormDataInfo={setFormDataInfo}
          />
        </div>
        <div className="new-global-table-container shift-table" style={{ paddingTop: "0 42px", maxHeight: "calc(100vh - 196px)" }}>

          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Sequence</th>
                <th>Order Ref</th>
                <th>Customer</th>
                <th>Branch</th>
                <th>Shift</th>
                <th>Employee</th>
                <th>Payment Method </th>

                <th>Amount</th>
                <th>Return</th>

              </tr>
            </thead>
            {isLoading ? (
              <TableLoading colSpan={9} rowcount={10} />
            ) : (
              <tbody>
                {hasData ?
                  list.map((r, i) => (
                    <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "white" : "#e9e7e7" }}>
                      <td>{r?.date}</td>
                      <td>{r?.sequence}</td>
                      <td>{r?.orderRef}</td>
                      <td>{r?.customer}</td>
                      <td>{r?.branch}</td>
                      <td>{r?.shift}</td>
                      <td>{r?.employe}</td>
                      <td>{r?.paymentMethod}</td>
                      <td style={{ color: r.points > 0 ? 'green' : 'red' }}>{r.points > 0 ? <NorthIcon /> : <SouthIcon />}{Math.abs(r.points)}</td>
                      <td>
                        <button className='btn-pos-wallet-return' onClick={()=>handleReturnClick(r)}>
                          <Undo2Icon/>
                        </button>
                      </td>
                    </tr>
                  )) :
                  <tr>
                    <td colSpan={10}>NO DATA</td>
                  </tr>
                }
              </tbody>

            )}

          </table>
        </div>
      </div>
      {/* return dialog */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <div className="pos-wallet-return-dialog">
          <div className="pos-wallet-return-header">Payment Method</div>
          <div className="pos-wallet-return-methods">
            <div onClick={() => handlePaymentMethodChange('Cash')} className={`pos-wallet-return-method ${selectedPaymentMethod === 'Cash' ? 'selected' : ''}`}>
              Cash
            </div>
            <div onClick={() => handlePaymentMethodChange('Card')} className={`pos-wallet-return-method ${selectedPaymentMethod === 'Card' ? 'selected' : ''}`}>
              Card
            </div>
            <div className="pos-wallet-return-method">
              Customer Wallet <span style={{ color: 'green' }}>0.00</span>
            </div>
          </div>
          <div className="pos-wallet-return-header pos-wallet-return-selected-header">Selected Payment Method</div>

          <div className="pos-wallet-return-selected">
           {[...Array(10)].map((r,i)=>(
            <div className="pos-wallet-return-item">
              {selectedPaymentMethod} <span>AED {paymentAmount.toFixed(2)}</span>
              <button className="pos-wallet-return-remove">X</button>
            </div>
           ))}
          </div>
          <div className="pos-wallet-return-actions">
            <button className="pos-wallet-return-button return" onClick={handleReturn}>Return</button>
            <button className="pos-wallet-return-button cancel" onClick={handleCancel}>Cancel</button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default PosWallet