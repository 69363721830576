import axios from "axios";
import { HEADERS } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import {
  setBillLoading,
  setDialogBox,
  setErrorSnack,
  setOrderIdInPOS,
  setSingleOrderInfo,
  setSnackMsg,
  setSuccessSnack,
} from "../Slice/billingScreenSlice";
import {
  setBtnSelected,
  setCustomer,
  setCustomerWallet,
} from "../Components/topBarSliceBilling";
import { setReceiptData } from "../Slice/viewOrdersBillingSlice";
import { setAuthPassword } from "../newBillingSlice";
import { singlePOSOrderInfoAPICall } from "./billingScreenSection";

// final placeOrder in payment
// export const finalPaymentPOSApiCall=async(body,clearFn,errorClearPayment)=>{
//     store.dispatch(setBillLoading(true))

//     await axios.post("pos/postPosPayment",body,HEADERS)
//         .then((res)=>{
//             if(res.status===200){
//                 store.dispatch(setOrderIdInPOS(null))
//                 store.dispatch(setCustomer(null))
//                 store.dispatch(setSnackMsg("Payment Succesfull 👍"))
//                 store.dispatch(setSuccessSnack(true))
//                 store.dispatch(setAuthPassword(""))
//                 store.dispatch(setDialogBox(null))
//                 clearFn()
//                 getInvoiceReceipt({orderId:res.data?._id})
//             }
//         })
//         .catch((err)=>{
//             errorClearPayment()
//             let message=err.response.status===500?"Internal Server Error !!":err.response.status===400?"Wrong payload passed":err.response.data
//             store.dispatch(setSnackMsg(message))
//             store.dispatch(setBillLoading(false))
//             store.dispatch(setErrorSnack(true))
//         })
// }

// new final place order
export const finalPaymentPOSApiCall = async (data) => {
  try {
    const res = await axios.post("credit/payposorder", data.body, HEADERS);
    posPaymentInvoiceApiCall({ body: { _id: data.body._id } });
    store.dispatch(setOrderIdInPOS(null))                
    store.dispatch(setCustomer(null))   
    store.dispatch(setDialogBox(null))        
    data.dispatch(setBtnSelected(""));
    data.clearFn()
    return res;
  } catch (err) {
    throw err;
  }
}; 

//pos payment invoice
export const posPaymentInvoiceApiCall = async (data) => {
  try {
    const res = await axios.post(
      "sales/viewBillOrderForInvoice",
      data.body,
      HEADERS
    );
    if (res.status === 200) {
      let pageInfo = store.getState().topBarSliceBilling.btnClicked;
        store.dispatch(setReceiptData(res.data));
        !(pageInfo === "viewOrder" || pageInfo === "heldOrder") &&
          store.dispatch(setDialogBox("invoicePrint"));
    }
    return res;
  } catch (err) {
    throw err;
  }
};

// refund in payment
export const refundPaymentApiCall = async (body, clearFn) => {
  store.dispatch(setBillLoading(true));

  await axios
    .post("pos/refundAndReturnOrd", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(setOrderIdInPOS(null));
        store.dispatch(setSingleOrderInfo(null));
        store.dispatch(setBillLoading(false));
        store.dispatch(setBtnSelected(null));
        store.dispatch(setSnackMsg("Refund Succesfull 👍"));
        store.dispatch(setSuccessSnack(true));
        store.dispatch(setCustomer(null));
        clearFn();
      }
    })
    .catch((err) => {
      let message =
        err.response.status === 500
          ? "Internal Server Error !!"
          : err.response.data;
      store.dispatch(setSnackMsg(message));
      store.dispatch(setBillLoading(false));
      store.dispatch(setErrorSnack(true));
    });
};
// refund update payment
export const refundUpdatePaymentApiCall = async (body, clearFn) => {
  store.dispatch(setBillLoading(true));
  await axios
    .post("pos/returnOrderPayment", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        clearFn();
        store.dispatch(setOrderIdInPOS(null));
        store.dispatch(setSingleOrderInfo(null));
        store.dispatch(setBillLoading(false));
        store.dispatch(setBtnSelected("viewOrder"));
        store.dispatch(setSnackMsg("Refund Succesfull 👍"));
        store.dispatch(setSuccessSnack(true));
      }
    })
    .catch((err) => {
      let message =
        err.response.status === 500
          ? "Internal Server Error !!"
          : err.response.data;
      store.dispatch(setSnackMsg(message));
      store.dispatch(setBillLoading(false));
      store.dispatch(setErrorSnack(true));
    });
};

export const getCustomerWallet = async (body) => {
  await axios
    .post("sales/viewCustomerWallet", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(setCustomerWallet(res.data));
      }
    })
    .catch((err) => {
      store.dispatch(setCustomerWallet(null));
    });
};

export const getInvoiceReceipt = async (body) => {
  await axios
    .post("sales/viewBillOrderById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        let pageInfo = store.getState().topBarSliceBilling.btnClicked;
        store.dispatch(setReceiptData(res.data));
        !(pageInfo === "viewOrder" || pageInfo === "heldOrder") &&
          store.dispatch(setDialogBox("invoicePrint"));
      }
    })
    .catch((err) => {
      store.dispatch(setSnackMsg("Failed to fetch receipt data!!"));
      store.dispatch(setErrorSnack(true));
      store.dispatch(setReceiptData(null));
    });
};
export const deliveryReceiptInfo = async (body) => {
  await axios
    .post("sales/viewBillOrderById", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(setReceiptData(res.data));
        store.dispatch(setBillLoading(false));
        store.dispatch(setDialogBox("deliveryPrint"));
      }
    })
    .catch((err) => {
      store.dispatch(setSnackMsg("Failed to fetch receipt data!!"));
      store.dispatch(setErrorSnack(true));
      store.dispatch(setReceiptData(null));
    });
};
