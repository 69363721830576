import React, { useEffect, useRef, useState } from "react";
import TopBar from "./TopBar";
import { Dialog, IconButton, Skeleton, Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useDispatch, useSelector } from "react-redux";
import { setBtnSelected, setCustomer } from "./topBarSliceBilling";
import dummyProductItemImg from "../../../../../Assets/BillingImages/emptyFoodImg.svg";
import {
  setBillLoading,
  setDialogBox,
  setErrorSnack,
  setOrderIdInPOS,
  setSingleOrderInfo,
} from "../Slice/billingScreenSlice";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import FilterButton from "../Utilities/FilterButton";
import {
  setApplyBtnClicked,
  setFilteredOrderType,
  setStatusOptions,
} from "../Utilities/filterButtonSlice";
import {
  cancelHeldOrder,
  cancelOngoingOrderApiCall,
  deleteBookingOrder,
  listHeldOrders,
  listOrdersForBillingAPI,
  listOrdersPaginationAPI,
  viewBillOrdersAPI,
} from "../API/viewOrderSection";
import { convertDateFormat } from "../../../../../Js/Date";
import emptyOrderImg from ".././image/emptyOrder.png";
import BackspaceIcon from "@mui/icons-material/Backspace";
import {
  setPageIndexForViewOrder,
  setPaginationForViewOrder,
} from "../Slice/viewOrdersBillingSlice";
import LinearLoading from "../Utilities/LinearLoading";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { PrintOutlined } from "@mui/icons-material";
import PosReceiptTemplate from "../../../components/template/posReceipt/PosReceiptTemplate";
import {
  getInvoiceReceipt,
  posPaymentInvoiceApiCall,
} from "../API/billingPaymentSection";
import { sendMessageToWhatsApp } from "../newBillingAPI";
import html2pdf from "html2pdf.js";
import {
  setSnackMsg,
  setSubmitLoading,
} from "../../../../../Redux/Dashboard/mainDashBoardSlice";
import Swal from "sweetalert2";
import { printRequestAPICall } from "../../../Settings/Device Settings/deviceSettingsAPI";
import {
  getPPI,
  numToWords,
  urlToBlob,
} from "../../../../../Js/generalFunctions";
import UpdateOrderAuth from "./UpdateOrderAuth";

function ViewOrders({ currency }) {
  const dispatch = useDispatch();
  let invoicePrint = useRef();

  const decimalPosition = localStorage.getItem("decimalPosition");
  const userInfo = useSelector((state) => state.loginResponseSlice.value);

  // redux states

  const {
    ordersList,
    tableLoading,
    cartLoading,
    heldOrders,
    pageIndex,
    pagination,
    receiptData,
  } = useSelector((state) => state.viewOrdersBillingSlice);
  const { singleOrderInfo, orderIdForPos, dialogOpen } = useSelector(
    (state) => state.billingScreenSlice
  );
  // console.log("singleOrderInfo",singleOrderInfo)
  const { btnClicked, searchBarInput } = useSelector(
    (state) => state.topBarSliceBilling
  );

  const { authPassWord } = useSelector((state) => state.newBillingSlice);
  const { filteredOrderType, filteredStatus, applyBtnClicked } = useSelector(
    (state) => state.filterButtonSlice
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );
  const posSettings = useSelector((state) => state.posSettingSlice.apiResList);

  const paginationLoading = useSelector(
    (state) => state.mainDashBoardSlice.tableLoading
  );
  const { currencyDetails } = useSelector((state) => state.mainDashBoardSlice);

  const [switchTabs, setSwitchTabs] = useState([
    "All",
    "Dine In",
    "Take Away",
    "Delivery",
  ]);
  //   useStates
  const [cartItems, setCartItems] = useState([]);
  const qtyViewOrderRefs = useRef(cartItems?.map(() => React.createRef()));
  const priceViewOrderRefs = useRef(cartItems?.map(() => React.createRef()));
  const [input, setInput] = useState("");
  const [selectedCartRow, setSelectedCartRow] = useState(null);
  const [inputSelection, setInputSelection] = useState(null);
  const [isCartModified, setIsCartModified] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  const [gridView, setGridView] = useState(btnClicked === "heldOrder");
  const [billTypeOption, setBillTypeOption] = useState("All");

  // pagination
  // console.log("orderHistory",orderHistory)
  // console.log("pageIndex", pageIndex);
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight + 100 >= scrollHeight) {
      if (
        btnClicked === "viewOrder" &&
        orderHistory?.length >= 30 &&
        !paginationLoading &&
        pagination !== "max"
      ) {
        dispatch(setPageIndexForViewOrder(pageIndex + 1));
        dispatch(setPaginationForViewOrder(true));
      }
    }
  };
  // view order single row click

  const viewOrderSingleClick = (row) => () => {
    dispatch(setOrderIdInPOS(row?._id));
    // getInvoiceReceipt({ _id: row?._id });
    posPaymentInvoiceApiCall({ body: { _id: row?._id } });
  };

  // keypad onChange
  const handleButtonClick = (value) => {
    if (selectedCartRow !== null) {
      const updatedCartItems = [...cartItems];
      const updatedCartItem = { ...updatedCartItems[selectedCartRow] };

      if (inputSelection === "qty") {
        if (updatedCartItem?.qty < Number(input + value)) {
          dispatch(
            setSnackMsg("Return quantity is greater than order quantity !!")
          );
          dispatch(setErrorSnack(true));
        } else {
          setInput((prevInput) => prevInput + value);
          updatedCartItem.retQty = Number(input + value);
        }
      } else if (inputSelection === "price") {
        setInput((prevInput) => prevInput + value);
        updatedCartItem.unitPrice = Number(input + value);
      }

      updatedCartItems[selectedCartRow] = updatedCartItem;

      setCartItems(updatedCartItems);
      setIsCartModified(!isCartModified);
    }
  };

  const keyPadBacksPace = () => {
    if (input !== "") {
      const updatedCartItems = [...cartItems];
      const updatedCartItem = { ...updatedCartItems[selectedCartRow] };
      if (inputSelection === "qty") {
        updatedCartItem.retQty = Number(input.slice(0, -1));
      } else if (inputSelection === "price") {
        updatedCartItem.unitPrice = Number(input.slice(0, -1));
      }
      updatedCartItems[selectedCartRow] = updatedCartItem;
      setCartItems(updatedCartItems);
      setIsCartModified(!isCartModified);
      setInput(input.slice(0, -1));
    }
  };
  // refund button onclick
  const finalPlaceOrder = () => {
    if (singleOrderInfo?.status === "RETURN") {
      let subTotal = singleOrderInfo?.subTotal;
      let amtTotal = Math.abs(singleOrderInfo?.amountDue);
      let totalExclusive = singleOrderInfo?.totalExclusive;
      let totalInclusive = singleOrderInfo?.totalInclusive;

      let payload = {
        mainOrdId: singleOrderInfo?._id,
        branchId: singleOrderInfo?.branchId,
        cusId: singleOrderInfo?.cusId,
        orderInfo: cartItems,
        subTotal,
        discountAmt: 0,
        totalExclusive,
        totalInclusive,
        amtTotal,
        type: "refund",
        status: singleOrderInfo?.status,
      };

      dispatch(setSingleOrderInfo(payload));
    }
    dispatch(setBtnSelected("payment"));
  };

  //update order
  const viewOrderUpdateOrder = () => {
    if (btnClicked === "viewOrder") {
      dispatch(setBtnSelected(null));
      dispatch(setOrderIdInPOS(null));
    } else {
      dispatch(setBtnSelected(null));
    }
  };
  // input the changes of quantity from cart
  const cartQuantityChange = (index, type) => (e) => {
    setInput("");
    const updatedCartItems = [...cartItems];
    const updatedCartItem = { ...updatedCartItems[index] };
    console.log("e", e.target.value);
    console.log("updatedCartItem", updatedCartItem);

    // console.log("updatedCartItems",updatedCartItems)

    if (type === "retQty") {
      console.log("updatedCartItem?.qty", updatedCartItem?.qty);
      if (updatedCartItem?.qty < Number(e.target.value)) {
        dispatch(
          setSnackMsg("Return quantity is greater than order quantity !!")
        );
        dispatch(setErrorSnack(true));
      } else {
        updatedCartItem[type] = Number(e.target.value);
      }
    } else {
      updatedCartItem[type] = Number(e.target.value);
    }

    updatedCartItems[index] = updatedCartItem;
    setCartItems(updatedCartItems);
    setIsCartModified(!isCartModified);
  };
  // qty,price,note button
  const keyPadTypeSelection = (key, index) => {
    // setInput("")
    if (key === "qty") {
      let retQty = cartItems[index || selectedCartRow]?.retQty?.toString();
      setInput(retQty || "");
    } else if (key === "price") {
      setInput(cartItems[index || selectedCartRow]?.unitPrice?.toString());
    } else {
      setInput("");
    }
    setInputSelection(key);
    const inputRef =
      key === "qty"
        ? qtyViewOrderRefs.current[index || selectedCartRow]
        : key === "price"
        ? priceViewOrderRefs.current[selectedCartRow]
        : null;

    if (inputRef && inputRef.current) {
      const originalType = inputRef.current.type;
      inputRef.current.type = "text";

      inputRef.current.focus();

      setTimeout(() => {
        inputRef.current.setSelectionRange(
          inputRef.current.value.length,
          inputRef.current.value.length
        );
        inputRef.current.type = originalType;
      }, 0);
    }

    if (key === "note") {
      dispatch(setDialogBox("addNote"));
    }
  };
  // cart row
  const cartRowClick = (index) => {
    setSelectedCartRow(index === selectedCartRow ? null : index);
    if (index === selectedCartRow) {
      setInputSelection(null);
    } else {
      keyPadTypeSelection("qty", index);
    }
  };
  const calculateAndSetCalculatedValues = () => {
    const calculatedValues = cartItems?.map((row) => {
      if (row?.retQty && Number(row.retQty) > 0) {
        let totalAmt = Number(row?.retQty) * Number(row?.unitPrice);
        let subtotalBeforeTax = totalAmt.toFixed(decimalPosition);
        let totalTax = row?.taxes?.reduce((sum, item) => sum + item?.amount, 0);
        let totalExclTax = (subtotalBeforeTax * totalTax) / 100;

        if (row?.includedPrice === "true" || row?.includedPrice === true) {
          let totalIncl =
            (Number(subtotalBeforeTax) * totalTax) / (100 + totalTax);

          return {
            ...row,
            inclusiveTax: totalIncl,
            exclusiveTax: 0,
            subTotalWoTax: totalAmt,
            lineTotal: totalAmt,
          };
        } else if (
          row?.includedPrice === "false" ||
          row?.includedPrice === false
        ) {
          return {
            ...row,
            exclusiveTax: totalExclTax,
            inclusiveTax: 0,
            subTotalWoTax: totalAmt,
            lineTotal: totalAmt + totalExclTax,
          };
        } else {
          let totalAmt = Number(row?.retQty) * Number(row?.unitPrice);
          return {
            ...row,
            exclusiveTax: 0,
            inclusiveTax: 0,
            subTotalWoTax: totalAmt,
            lineTotal: totalAmt,
          };
        }
      } else {
        // If returnQty is not defined or not greater than 0, return the original row
        return row;
      }
    });
    setCartItems(calculatedValues);
  };

  const disableCompleteOrder =
    singleOrderInfo?.status === "completed" ||
    singleOrderInfo?.status === "POSTED";

  // refund button Clicked
  const returnQtyOrderInfo = cartItems
    ?.map(
      (item) =>
        item?.qty > 0 &&
        item?.retQty &&
        item?.retQty > 0 && {
          productId: item?.productId,
          desccription: item?.desccription,
          customerNote: item?.customerNote,
          qty: -item?.retQty,
          uom: item?.uom,
          unitPrice: item?.unitPrice,
          subTotalWoTax: -item?.subTotalWoTax,
          lineTotal: -item?.lineTotal,
          taxes: item?.taxes,
          exclusiveTax: item?.exclusiveTax,
          inclusiveTax: item?.inclusiveTax,
          itemType: item?.itemType,
          includedPrice:
            item?.includedPrice === "true" || item?.includedPrice === true
              ? true
              : item?.includedPrice === "false" || item?.includedPrice === false
              ? false
              : null,
        }
    )
    .filter(Boolean);

  const clickRefund = () => {

    //---------------------------Test-----------------------------------
    // console.log("singleOrderInfo", singleOrderInfo);
    // console.log("cartItems", cartItems);
    // console.log("returnQtyOrderInfo", returnQtyOrderInfo);

    // const refundOrderPayload = {
    //   orderPk: singleOrderInfo?.orderPk,
    //   returnInfo: returnQtyOrderInfo,
    //   paymentMethod: [
    //     {
    //       type: "cash",
    //       journalId: "658e5d950dda53006c5ae9e7",
    //       paidAmt: 150,
    //       cardId: null,
    //     },
    //   ],
    //   branchId: singleOrderInfo?.branchId,
    //   shiftPk: singleOrderInfo?.shiftPk,
    //   date: singleOrderInfo?.orderDate,
    //   returnTotal: returnQtyOrderInfo?.reduce(
    //     (sum, item) => sum + -item?.lineTotal,
    //     0
    //   ),
    //   returnType: singleOrderInfo?.ordType,
    // };
    // console.log("refundOrderPayload", refundOrderPayload);
//---------------------------------------------------------------------
    let subTotal = returnQtyOrderInfo?.reduce(
      (sum, item) => sum + -item?.subTotalWoTax,
      0
    );
    let amtTotal = returnQtyOrderInfo?.reduce(
      (sum, item) => sum + -item?.lineTotal,
      0
    );
    let totalExclusive = returnQtyOrderInfo?.reduce(
      (sum, item) => sum + item?.exclusiveTax,
      0
    );
    let totalInclusive = returnQtyOrderInfo?.reduce(
      (sum, item) => sum + item?.inclusiveTax,
      0
    );

    let totalReturnQtyNos = returnQtyOrderInfo?.reduce(
      (sum, item) => sum + -item?.qty,
      0
    );
    let totalQtyNos = cartItems?.reduce(
      (sum, item) => sum + item?.originalQty,
      0
    );
    let discountedRate = singleOrderInfo?.discountAmt;

    let discountPerQty = discountedRate / totalQtyNos;

    let discountAmount = totalReturnQtyNos * discountPerQty;

    let payload = {
      mainOrdId: singleOrderInfo?._id,
      branchId: singleOrderInfo?.branchId,
      orderInfo: returnQtyOrderInfo,
      cusId: singleOrderInfo?.cusId,
      subTotal,
      discountAmt: Number(discountAmount),
      totalExclusive,
      totalInclusive,
      amtTotal,
      type: "refund",
    };

    if (returnQtyOrderInfo.length === 0) {
      dispatch(setSnackMsg("No items selected to return!!"));
      dispatch(setErrorSnack(true));
    } else {
      dispatch(setSingleOrderInfo(payload));
      dispatch(setBtnSelected("payment"));
    }
  };

  // filter order type onChange
  const onChangeBillType = (key) => () => {
    let option =
      key === "Dine In"
        ? "DINEIN"
        : key === "Take Away"
        ? "TAKEAWAY"
        : key === "All"
        ? null
        : "DELIVERY";
    setBillTypeOption(key);
    dispatch(setFilteredOrderType(option));
    dispatch(setApplyBtnClicked("clicked"));
    dispatch(setOrderIdInPOS(null));
  };

  // send whatsapp message
  const sendMessageToWs = async () => {
    try {
      dispatch(setSubmitLoading(true));
      // Convert to PDF
      const content = invoicePrint.current;

      const options = {
        filename: "sample.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2, logging: false },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      // Generate PDF
      const pdfBlob = await html2pdf()
        .from(content)
        .set(options)
        .outputPdf("blob");

      // Create FormData object and append the PDF file
      const formData = new FormData();
      formData.append("pdfFile", pdfBlob, "sample.pdf");
      formData.append("_id", orderIdForPos);
      formData.append("type", "POS");

      // Now you can pass formData to your API

      const response = await sendMessageToWhatsApp(formData);
    } catch (error) {
      console.log("Error sending message:", error);
      // Handle error if required
    }
  };
  const posReceiptPrint = useReactToPrint({
    content: () => invoicePrint.current,
  });
  // console.log("posReceiptPrint",posReceiptPrint)

  const receiptPrintClick = async () => {
    dispatch(setBillLoading(true));
    try {
      let printSettingsString = localStorage.getItem("PrinterSettings");
      let printSettings = JSON.parse(printSettingsString);

      const companyLogo =
        companyProfileData?.logo && (await urlToBlob(companyProfileData?.logo));
      const domainAddress = `${window.location.protocol}//${
        window.location.host
      }/posReaderPreview?path=admin/viewqrcodedata&db=${encodeURIComponent(
        receiptData?.db
      )}&vendororcusid=${encodeURIComponent(
        receiptData?.cusId
      )}&type=${encodeURIComponent("POS")}&orderId=${encodeURIComponent(
        receiptData?._id
      )}`;

      const formData = new FormData();
      formData.append("printerName", printSettings?.receiptPrinter);
      formData.append(
        "printerWidth",
        printSettings?.receiptSize === "80" ? 48 : 32
      );
      formData.append("_id", receiptData?._id);
      formData.append(
        "customerName",
        JSON.stringify(receiptData?.customerName)
      );
      formData.append("cusId", receiptData?.cusId);
      formData.append("cashier", receiptData?.cashier || "");
      formData.append("waiterName", receiptData?.waiterName || "");
      formData.append("tables", JSON.stringify(receiptData?.tables));
      formData.append("name", receiptData?.name);
      formData.append("orderDate", receiptData?.orderDate);
      formData.append("ordType", receiptData?.ordType);
      formData.append(
        "totalExclusive",
        receiptData?.totalExclusive?.toFixed(decimalPosition)
      );
      formData.append(
        "totalInclusive",
        receiptData?.totalInclusive?.toFixed(decimalPosition)
      );
      formData.append(
        "amtTotal",
        receiptData?.amtTotal?.toFixed(decimalPosition)
      );
      formData.append(
        "discountAmt",
        receiptData?.discountAmt?.toFixed(decimalPosition)
      );
      formData.append(
        "taxableAmt",
        receiptData?.taxableAmt?.toFixed(decimalPosition)
      );
      formData.append("orderInfo", JSON.stringify(receiptData?.orderInfo));
      formData.append("paymentInfo", JSON.stringify(receiptData?.paymentInfo));
      formData.append("kotInfo", JSON.stringify(receiptData?.kotInfo));
      formData.append("contactNumber", receiptData?.contactNumber || "");
      formData.append("address", receiptData?.address || "");
      formData.append("nativeAddress", receiptData?.nativeAddress || "");
      formData.append("trnNumber", receiptData?.trnNumber || "");
      formData.append("email", receiptData?.email || "");
      formData.append("taxDetails", JSON.stringify(receiptData?.taxDetails));
      formData.append("logoFile", companyLogo || null);
      formData.append("companyName", companyProfileData?.companyName || "###");
      formData.append("invoiceUrl", domainAddress || window.location.host);
      formData.append("mode", printSettings?.receiptImgMode || 1);
      formData.append("logoHeight", printSettings?.logoHeight || 20);
      formData.append(
        "deliveryCharge",
        Number(receiptData?.deliveryCharge)?.toFixed(decimalPosition) || 0.0
      );
      formData.append(
        "amoutInWords",
        numToWords(Number(receiptData?.amtTotal?.toFixed(decimalPosition)))
      );
      formData.append("enableBarcode", printSettings?.enableBarcode || false);
      formData.append("enableQrCode", printSettings?.enableQrCode || false);
      if (printSettings?.receiptPrinter) {
        printRequestAPICall(formData, posReceiptPrint);
      } else {
        posReceiptPrint();
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      dispatch(setBillLoading(false));
    }
  };

  // const receiptPrintClick = async () => {
  //   dispatch(setBillLoading(true));
  //   try {
  //     const receiptHTML = invoicePrint.current;
  //     // Get the height of the content
  //     const contentHeight = receiptHTML.offsetHeight;
  //     // Get the PPI (Pixels Per Inch) of the current screen
  //     const ppi = getPPI();
  //     // Convert inches to mm for jsPDF
  //     const pixelsToMM = (pixels) => pixels * (25.4 / ppi);
  //     const contentHeightInMM = pixelsToMM(contentHeight);

  //     let printSettingsString=localStorage.getItem('PrinterSettings')
  //     let printSettings=JSON.parse(printSettingsString)

  //     const opt = {
  //       margin: 0,
  //       filename: 'receipt.pdf',
  //       html2canvas: { scale: 4, useCORS: true },
  //       jsPDF: { unit: 'mm', format: [Number(printSettings?.receiptSize || 80), contentHeightInMM*1.2] }
  //     };
  //     // Convert to PDF
  //     const pdfBlob = await html2pdf().from(receiptHTML).set(opt).outputPdf('blob');

  //     const formData = new FormData();
  //     formData.append('pdfFile', pdfBlob);
  //     formData.append('printer_name',printSettings?.receiptPrinter);

  //     printRequestAPICall(formData,posReceiptPrint)
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   } finally {
  //     dispatch(setBillLoading(false));
  //   }
  // };
  // cancel ongoing orders
  const cancelOngoingOrder = (status) => () => {
    console.log("status", status);

    const payload = {
      orderId: orderIdForPos,
      branchId: userInfo?.branchPk,
      date: new Date().toISOString().split("T")[0],
    };

    // validate empty fields
    const emptyFields = Object.keys(payload).filter(
      (key) =>
        payload[key] === null ||
        payload[key] === undefined ||
        payload[key] === ""
    );
    // if no empty fields
    if (emptyFields.length === 0) {
      if (status === "DRAFT") {
        cancelHeldOrder({ body: payload });
      }
    } else {
      // console.log("emptyFields",emptyFields)
      throw new Error(`some fields are empty :${emptyFields.join(",")}`);
    }

    // let payload = {
    //   orderId: orderIdForPos,
    //   branchId: userInfo?.branchPk,
    //   password: authPassWord,
    // };

    // let postFn = () => {
    //   dispatch(setApplyBtnClicked("clicked"));
    // };

    // if (dialogOpen === "cancelAuthPassword" || !posSettings?.cancelOrdAuth) {
    //   Swal.fire({
    //     text: "Confirm to cancel this order",
    //     icon: "warning",
    //     confirmButtonColor: "#f80000",
    //     confirmButtonText: "Confirm",
    //     cancelButtonText: "back",
    //     customClass: {
    //       cancelButton: "sweet-alert-cancel-button",
    //       confirmButton: "sweet-alert-submit-button",
    //     },
    //     showCancelButton: true,
    //     reverseButtons: true,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       if (status === "DRAFT") {
    // cancelOngoingOrderApiCall(payload, postFn);
    //       } else {
    //         deleteBookingOrder(payload, postFn);
    //       }
    //     }
    //   });
    // } else {
    //   dispatch(setDialogBox("cancelAuthPassword"));
    // }
  };
  useEffect(() => {
    calculateAndSetCalculatedValues();
  }, [isCartModified]);

  useEffect(() => {
    // console.log("singleOrderInfo", singleOrderInfo);
    if (singleOrderInfo !== null) {
      let updatedCartItems = singleOrderInfo?.items?.map((obj) => ({
        ...obj,
        qty: obj?.qty - obj?.retQty,
        originalQty: obj?.qty,
        retQty: obj?.qty - obj?.retQty === 0 ? obj?.retQty : 0,
      }));
      setCartItems(updatedCartItems);
    } else {
      setCartItems([]);
    }
  }, [singleOrderInfo]);

  useEffect(() => {
    qtyViewOrderRefs.current = cartItems?.map(() => React.createRef());
    priceViewOrderRefs.current = cartItems?.map(() => React.createRef());
  }, [cartItems]);

  // console.log("heldOrders",heldOrders)
  // console.log("ordersList",ordersList)

  useEffect(() => {
    let status = [];
    if (btnClicked === "viewOrder") {
      // console.log("ordersList",ordersList)
      // setGridView(false);
      setOrderHistory(ordersList);
      status = ["COMPLETED", "RETURN", "CANCEL"];
    } else {
      setOrderHistory(heldOrders);
      console.log("heldOrders", heldOrders);
      status = ["BOOKING", "DRAFT"];
    }
    dispatch(setOrderIdInPOS(null));
    dispatch(setStatusOptions(status));
    setSelectedCartRow(null);
    setInputSelection(null);
  }, [btnClicked, heldOrders, ordersList]);
  // console.log("ordersList",ordersList)
  // api call

  useEffect(() => {
    if (applyBtnClicked === "clicked") {
      let status = filteredStatus === "COMPLETED" ? "DONE" : filteredStatus;
      let payload = {
        branchId: userInfo?.branchPk,
        type: btnClicked === "viewOrder" ? 2 : 1,
        search: searchBarInput,
        ordType: filteredOrderType,
        status,
        isHeldOrder: btnClicked === "viewOrder" ? false : true,
      };
      btnClicked === "viewOrder"
        ? viewBillOrdersAPI({ body: payload, setOrderHistory })
        : viewBillOrdersAPI({ body: payload, setOrderHistory });
      dispatch(setOrderIdInPOS(null));
      dispatch(setApplyBtnClicked(false));
    }
  }, [applyBtnClicked, searchBarInput]);

  useEffect(() => {
    if (pagination === true) {
      let status = filteredStatus === "COMPLETED" ? "DONE" : filteredStatus;
      let payload = {
        branchId: userInfo?.branchPk,
        type: btnClicked === "viewOrder" ? 2 : 1,
        index: btnClicked === "viewOrder" ? pageIndex : 0,
        status,
        isHeldOrder: btnClicked === "viewOrder" ? false : true,
      };
      listOrdersPaginationAPI(payload);
      dispatch(setOrderIdInPOS(null));
    }
  }, [pagination]);

  // console.log("cartItems",cartItems)

  return (
    <div className="billing-main-container">
      <div className="billing-left-container">
        <div className="flex-row" style={{ gap: "5px" }}>
          <div
            className="justify-center"
            style={{ backgroundColor: "#fff", width: "40px" }}
          >
            <IconButton
              onClick={() => {
                dispatch(setOrderIdInPOS(null));
                dispatch(setSingleOrderInfo(null));
                dispatch(setCustomer(null));
                dispatch(setBtnSelected(null));
              }}
            >
              <HomeIcon sx={{ color: "green" }} />
            </IconButton>
          </div>
          <TopBar />
        </div>

        <div className="billing-payment-ctn">
          <div
            className="billing-payment-ctn-left"
            style={{ height: "calc(100vh - 120px)", maxHeight: "fit-content" }}
          >
            <div className="justify-space-between" style={{ padding: "0 1%" }}>
              <h4>
                {btnClicked === "heldOrder" ? "Held Orders" : "View Orders"}
              </h4>
              <div className="justify-center reservation-top-right">
                {/* {
                  btnClicked === "heldOrder" &&
                  <div
                    className="new-billing-order-type-switch"
                    style={{width:"auto"}}
                  >
                    {
                      switchTabs?.map((option) => (
                        <div
                          key={option}
                          className={`switch-bill-type ${billTypeOption === option ? 'active' : ''}`}
                          onClick={onChangeBillType(option)}
                          style={{paddingBottom:billTypeOption === option ?0:"4px"}}
                        >
                          <p readOnly style={{ margin: "0", fontSize: "0.9rem" }}>
                            {option}
                          </p>
                        </div>
                      ))
                    }
                  </div>
                } */}
                <FilterButton />

                {/* {
                btnClicked==="heldOrder" &&  
                <>
                <input
                  className="toggle-checkbox"
                  type={"checkbox"}
                  checked={gridView}
                  onChange={(e)=>setGridView(e.target.checked)}
                  name="gridView"
                  />
                  </>
                  } */}
              </div>
            </div>
            {
              <div
                style={{ maxHeight: "90vh" }}
                className="payment-view-order-table new-global-table-container"
                onScroll={handleScroll}
              >
                <table>
                  <thead>
                    <tr>
                      <th style={{ paddingLeft: "15px" }}>No</th>
                      <th>Date</th>
                      <Tooltip title="Order Number">
                        <th>O/No</th>
                      </Tooltip>
                      {/* {billTypeOption === "All" && (
                        <Tooltip title="Order Type">
                          <th>O/Type</th>
                        </Tooltip>
                      )} */}
                      <th>Customer</th>
                      <th>Mobile</th>

                      <th>Discount</th>
                      <th>Total Amount</th>
                      <th>Status</th>
                      {/* <Tooltip title="Referal Code">
                            <th>Referal</th>
                          </Tooltip> */}

                      {/* <th>Amount</th>
                      {btnClicked === "viewOrder" && <th>Balance</th>}
                      {btnClicked === "heldOrder" && <th>Timer</th>}
                      {(btnClicked === "viewOrder" ||
                        billTypeOption === "All" ||
                        billTypeOption === "Dine In") && <th>Waiter</th>}
                      <th>Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {tableLoading ? (
                      [...Array(10)].map((r, i) => (
                        <tr key={i}>
                          <td colSpan={8}>
                            <Skeleton
                              variant="rectangular"
                              animation="wave"
                              width={"100%"}
                              height={40}
                            />
                          </td>
                        </tr>
                      ))
                    ) : orderHistory?.length !== 0 ? (
                      orderHistory?.map((r, i) => (
                        <tr key={i} onClick={viewOrderSingleClick(r)}>
                          <td style={{ paddingLeft: "15px" }}>{i + 1}</td>
                          <td>{convertDateFormat(r?.date)}</td>
                          <td>{r?.orderId}</td>
                          <td>{r?.customer}</td>
                          {/* {billTypeOption === "All" && <td>{r?.ordType}</td>} */}

                          <td>{r?.mobile}</td>
                          <td>{r?.discount}</td>
                          <td>{r?.totalAmount}</td>
                          <td className="table-data-box">
                            {r?.status === "DRAFT" ? (
                              <span className="draft">
                                &nbsp;&nbsp; DRAFT&nbsp;&nbsp;{" "}
                              </span>
                            ) : r?.status === "POSTED" ||
                              r?.status === "completed" ? (
                              <span className="post"> COMPLETED</span>
                            ) : r?.status === "CANCELLED" ? (
                              <span className="cancel">
                                &nbsp;&nbsp;CANCEL&nbsp;&nbsp;
                              </span>
                            ) : r?.status === "RETURN" ? (
                              <span className="return">
                                &nbsp;&nbsp;RETURN&nbsp;&nbsp;
                              </span>
                            ) : r?.status === "CHDRAFT" ? (
                              <span className="requested">BOOKING</span>
                            ) : (
                              <span>{r?.status}</span>
                            )}
                          </td>
                          {/* {btnClicked === "viewOrder" && (
                            <td>{r?.customerMobile || "-NIL-"}</td>
                          )} */}
                          {/* {(billTypeOption === "Delivery" ||
                            btnClicked === "viewOrder") && (
                            <>
                              <td style={{ maxWidth: "150px" }}>
                                {Object.keys(r?.custAddress || {}).map(
                                  (key, index, keys) => (
                                    <>
                                      {r?.custAddress[key]}
                                      {r?.custAddress[key] &&
                                        index < keys.length - 1 &&
                                        ", "}
                                    </>
                                  )
                                )}
                                {Object.values(r?.custAddress || {}).every(
                                  (val) => !val
                                ) && "-NIL-"}
                              </td>
                              <td>{r?.aggregatorName || "-NIL-"}</td>
                              <td>{r?.aggregatorRef || "-NIL-"}</td>
                            </>
                          )} */}
                          {/* <td>{r?.amtTotal?.toFixed(decimalPosition)}</td>
                          {btnClicked === "viewOrder" && (
                            <td>
                              <span
                                className={r?.amountDue > 0 ? "amount-due" : ""}
                              >
                                {r?.amountDue?.toFixed(decimalPosition)}
                              </span>
                            </td>
                          )}
                          {btnClicked === "heldOrder" && (
                            <td>
                              <TimerColumn
                                startTime={
                                  !(r?.status === "CHDRAFT") &&
                                  `${r?.orderDate} ${r?.orderTime}`
                                }
                              />
                            </td>
                          )}
                          {(btnClicked === "viewOrder" ||
                            billTypeOption === "All" ||
                            billTypeOption === "Dine In") && (
                            <td>{r?.waiterName || "-NIL-"}</td>
                          )}

                          <td className="table-data-box">
                            {r?.status === "DRAFT" ? (
                              <span className="draft">
                                &nbsp;&nbsp; DRAFT&nbsp;&nbsp;{" "}
                              </span>
                            ) : r?.status === "POSTED" ||
                              r?.status === "DONE" ? (
                              <span className="post"> COMPLETED</span>
                            ) : r?.status === "CANCELLED" ? (
                              <span className="cancel">
                                &nbsp;&nbsp;CANCEL&nbsp;&nbsp;
                              </span>
                            ) : r?.status === "RETURN" ? (
                              <span className="return">
                                &nbsp;&nbsp;RETURN&nbsp;&nbsp;
                              </span>
                            ) : r?.status === "CHDRAFT" ? (
                              <span className="requested">BOOKING</span>
                            ) : (
                              <span>{r?.status}</span>
                            )}
                          </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={btnClicked === "heldOrder" ? 15 : 20}>
                          No Records
                        </td>
                      </tr>
                    )}
                    {paginationLoading && (
                      <tr>
                        <td style={{ height: "15px" }} colSpan={19}>
                          <LinearLoading />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
      </div>
      {orderIdForPos && (
        <div className="flex-column">
          {orderIdForPos && (
            <div className="cart-table-top" style={{ height: "40px" }}>
              <p>Order ID</p>
              {cartLoading ? (
                <Skeleton width="80px" height={"30px"} />
              ) : (
                <p>{singleOrderInfo?.sequence || "Order ###"}</p>
              )}
              {
                <div
                  className="justify-center"
                  style={{ marginLeft: "auto", gap: "5px" }}
                >
                  <Tooltip title="Send reciept as whatsapp message">
                    <IconButton
                      onClick={() => sendMessageToWs()}
                      sx={{ backgroundColor: "transparent !important" }}
                    >
                      <WhatsAppIcon color="success" />
                    </IconButton>
                  </Tooltip>

                  <IconButton
                    onClick={() => receiptPrintClick()}
                    className="invoice-print-btn"
                    sx={{
                      backgroundColor: "transparent !important",
                    }}
                  >
                    <PrintOutlined
                      className="print-icon"
                      sx={{ color: "#000" }}
                    />
                  </IconButton>
                </div>
              }
            </div>
          )}

          <div
            className="billing-rightside-container"
            style={{ height: "84vh" }}
          >
            <div className="billing-right-card">
              {cartItems?.length == 0 ? (
                <div
                  className="billing-card-content"
                  style={{ backgroundColor: "transparent", boxShadow: "none" }}
                >
                  <img style={{ width: "100%" }} src={emptyOrderImg} alt="" />
                </div>
              ) : (
                cartItems?.map((cartItem, index) => (
                  <>
                    {cartLoading ? (
                      [...Array(5)].map((r, i) => (
                        <div
                          key={i}
                          className="billing-card-content"
                          style={{ justifyContent: "center" }}
                        >
                          <Skeleton width={"95%"} height="50px" />
                        </div>
                      ))
                    ) : (
                      <div
                        className="billing-card-content"
                        key={index}
                        style={{
                          paddingRight: "10px",
                          border:
                            selectedCartRow === index && "2px solid #088051",
                        }}
                        onClick={() =>
                          disableCompleteOrder &&
                          cartItem?.qty !== 0 &&
                          cartRowClick(index)
                        }
                      >
                        <div className="billing-card-image">
                          <img src={dummyProductItemImg} alt="" />
                        </div>
                        <div className="billing-card-details">
                          <h1>{cartItem.productName}</h1>
                          <p>{cartItem.customerNote}</p>
                        </div>
                        <div
                          className="cart-buttons-ctn"
                          style={{ gap: "5px" }}
                        >
                          <input
                            type="number"
                            value={cartItem.originalQty}
                            readOnly
                          />
                          {/* return quantity  */}
                          {disableCompleteOrder && (
                            <input
                              ref={qtyViewOrderRefs.current[index]}
                              onClick={(e) => {
                                inputSelection === "qty" &&
                                  selectedCartRow === index &&
                                  e.stopPropagation();
                              }}
                              type="number"
                              value={cartItem.retQty || ""}
                              placeholder="0"
                              style={{
                                border:
                                  cartItem?.originalQty > 0 &&
                                  "1px solid #f31704",
                                color: cartItem?.originalQty === 0 && "red",
                                fontWeight:
                                  cartItem?.originalQty === 0 && "600",
                              }}
                              onChange={cartQuantityChange(index, "retQty")}
                              readOnly={
                                selectedCartRow !== index ||
                                inputSelection !== "qty" ||
                                cartItem?.originalQty === 0
                              }
                            />
                          )}
                        </div>
                        <div
                          className="billing-cart-total-price"
                          style={{ fontSize: "0.8rem" }}
                        >
                          <p>
                            {currency?.symbol} {cartItem?.amount}
                          </p>
                          <p className="sub">
                            {currency?.symbol}
                            <input
                              type="number"
                              ref={priceViewOrderRefs.current[index]}
                              onClick={(e) => {
                                selectedCartRow === index &&
                                  disableCompleteOrder &&
                                  e.stopPropagation();
                              }}
                              style={{
                                width: `${
                                  String(cartItem?.itemRate).length * 9
                                }px`,
                                border:
                                  selectedCartRow === index &&
                                  disableCompleteOrder &&
                                  "1px solid #088051",
                                marginLeft:
                                  selectedCartRow === index &&
                                  disableCompleteOrder &&
                                  "5px",
                                minWidth: "20px",
                                textAlign: "end",
                              }}
                              onChange={cartQuantityChange(index, "unitPrice")}
                              value={cartItem?.itemRate}
                              readOnly={
                                selectedCartRow !== index ||
                                !disableCompleteOrder
                              }
                              onFocus={(e) => e.target.select()}
                            />
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ))
              )}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                className="subtotal-amount-ctn"
                style={{ marginBottom: "0", borderBottom: "0" }}
              >
                <p>Subtotal</p>
                <input
                  type="text"
                  value={`${currency?.symbol} ${
                    (btnClicked === "viewOrder"
                      ? returnQtyOrderInfo?.reduce(
                          (sum, item) => sum + -item?.lineTotal,
                          0
                        )
                      : singleOrderInfo?.netAmount?.toFixed(decimalPosition)) ||
                    "0.00"
                  }`}
                  readOnly
                />
              </div>
              <div
                className="subtotal-amount-ctn"
                style={{ marginTop: "0", borderTop: "0", paddingTop: "0" }}
              >
                <p>Amount Due</p>
                <input
                  type="text"
                  value={`${currency?.symbol} ${
                    singleOrderInfo?.amountDue?.toFixed(decimalPosition) ||
                    "0.00"
                  }`}
                  readOnly
                />
              </div>
              <div className="billing-keypad-section">
                <div className="billing-input-section">
                  <div className="row">
                    <button onClick={() => handleButtonClick("7")}>7</button>
                    <button onClick={() => handleButtonClick("8")}>8</button>
                    <button onClick={() => handleButtonClick("9")}>9</button>
                  </div>
                  <div className="row">
                    <button onClick={() => handleButtonClick("4")}>4</button>
                    <button onClick={() => handleButtonClick("5")}>5</button>
                    <button onClick={() => handleButtonClick("6")}>6</button>
                  </div>
                  <div className="row">
                    <button onClick={() => handleButtonClick("1")}>1</button>
                    <button onClick={() => handleButtonClick("2")}>2</button>
                    <button onClick={() => handleButtonClick("3")}>3</button>
                  </div>
                  <div className="row">
                    <button onClick={() => handleButtonClick(".")}>.</button>
                    <button onClick={() => handleButtonClick("0")}>0</button>
                    <button
                      onClick={keyPadBacksPace}
                      className="justify-center"
                    >
                      <BackspaceIcon />
                    </button>
                  </div>
                  <div className="row">
                    {inputSelection === "note" ? (
                      <button
                        onClick={() => keyPadTypeSelection(null)}
                        className="keypad-qty-btn closekey-pad-btn"
                        style={{ backgroundColor: "#478045" }}
                      >
                        <DoneOutlineIcon />
                      </button>
                    ) : (
                      <button
                        disabled={selectedCartRow === null}
                        onClick={() => keyPadTypeSelection("note")}
                        className={`${
                          selectedCartRow === null
                            ? "keypad-qty-btn-enable"
                            : ""
                        } keypad-qty-btn`}
                      >
                        Note
                      </button>
                    )}
                    {inputSelection === "qty" ? (
                      <button
                        disabled={selectedCartRow === null}
                        onClick={() => keyPadTypeSelection(null)}
                        className="keypad-qty-btn closekey-pad-btn"
                        style={{ backgroundColor: "#478045" }}
                      >
                        <DoneOutlineIcon />
                      </button>
                    ) : (
                      <button
                        onClick={() => keyPadTypeSelection("qty")}
                        disabled={selectedCartRow === null}
                        className={`${
                          selectedCartRow === null
                            ? "keypad-qty-btn-enable"
                            : ""
                        } keypad-qty-btn`}
                      >
                        Qty
                      </button>
                    )}
                    {inputSelection === "price" ? (
                      <button
                        onClick={() => keyPadTypeSelection(null)}
                        className="keypad-qty-btn closekey-pad-btn"
                        style={{ backgroundColor: "#478045" }}
                      >
                        <DoneOutlineIcon />
                      </button>
                    ) : (
                      <button
                        onClick={() => keyPadTypeSelection("price")}
                        disabled={selectedCartRow === null}
                        className={`${
                          selectedCartRow === null
                            ? "keypad-qty-btn-enable"
                            : ""
                        } keypad-qty-btn`}
                      >
                        Price
                      </button>
                    )}
                  </div>
                </div>
                <div className="billing-keypad-buttons">
                  {btnClicked === "heldOrder" ? (
                    <button
                      style={{ height: "73.333px" }}
                      onClick={cancelOngoingOrder(singleOrderInfo?.status)}
                      className={`billing-clear-button`}
                    >
                      CANCEL
                    </button>
                  ) : (
                    <button
                      style={{ height: "73.333px" }}
                      disabled={!disableCompleteOrder}
                      onClick={clickRefund}
                      className={`billing-clear-button ${
                        !disableCompleteOrder ? "disabled-refund" : ""
                      }`}
                    >
                      Refund
                    </button>
                  )}
                  <button
                    className={`billing-payment-button ${
                      !(
                        singleOrderInfo?.status === "DRAFT" ||
                        singleOrderInfo?.status === "CHDRAFT" ||
                        btnClicked === "viewOrder"
                      )
                        ? "disabled-order"
                        : ""
                    }`}
                    style={{ height: "73.333px" }}
                    disabled={
                      !(
                        singleOrderInfo?.status === "DRAFT" ||
                        singleOrderInfo?.status === "CHDRAFT" ||
                        btnClicked === "viewOrder"
                      )
                    }
                    onClick={viewOrderUpdateOrder}
                  >
                    {btnClicked === "heldOrder" ? "Update Order" : "Add Order"}
                  </button>
                  <button
                    onClick={finalPlaceOrder}
                    className={`billing-placeOrder-button ${
                      singleOrderInfo?.amountDue === 0 ||
                      singleOrderInfo?.status === "CHDRAFT" ||
                      singleOrderInfo?.status === "CANCELLED"
                        ? "disabled-payment"
                        : ""
                    }`}
                    style={{ height: "73.333px" }}
                    disabled={
                      singleOrderInfo?.amountDue === 0 ||
                      singleOrderInfo?.status === "CHDRAFT" ||
                      singleOrderInfo?.status === "CANCELLED"
                    }
                  >
                    Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="print-parent">
        <div ref={invoicePrint} className="pos-print-container">
          <PosReceiptTemplate />
        </div>
      </div>

      <Dialog
        open={dialogOpen === "cancelAuthPassword"}
        onClose={() => dispatch(setDialogBox(false))}
      >
        <UpdateOrderAuth
          text={"Cancel Order"}
          submitClick={cancelOngoingOrder(singleOrderInfo?.status)}
        />
      </Dialog>
    </div>
  );
}

export default ViewOrders;
