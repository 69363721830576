import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
  Autocomplete,
  CircularProgress,
  Alert,
  Grid
} from "@mui/material";
import { styled } from "@mui/system";
import { FaEye, FaEyeSlash, FaIndustry, FaArrowLeft } from "react-icons/fa";

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "2rem",
  borderRadius: "16px",
  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  minHeight: "600px",
  margin: "2rem auto",
  "@media (max-width: 900px)": {
    flexDirection: "column",
    padding: "1rem"
  }
}));

const ImageSection = styled(Box)({
  flex: 1,
  backgroundImage: "url(images.unsplash.com/photo-1558769132-cb1aea458c5e)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: "12px",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: "12px"
  }
});

const FormSection = styled(Box)({
  flex: 1,
  padding: "2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
});

const LoginForm = () => {
  const [formData, setFormData] = useState({
    companyCode: "",
    username: "",
    password: ""
  });
  const [resetFormData, setResetFormData] = useState({
    country: null,
    mobileNumber: ""
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isResetForm, setIsResetForm] = useState(false);
  // Added new states for OTP verification
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(0);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const startOtpTimer = () => {
    setTimer(30);
    setCanResend(false);
  };

  const companyCodes = [
    { label: "TEXTILE001", name: "ABC Textiles" },
    { label: "TEXTILE002", name: "XYZ Fabrics" },
    { label: "TEXTILE003", name: "Global Yarns" }
  ];

  const countries = [
    { code: "+1", name: "United States" },
    { code: "+44", name: "United Kingdom" },
    { code: "+91", name: "India" },
    { code: "+86", name: "China" },
    { code: "+81", name: "Japan" }
  ];

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
    validateField(field, event.target.value);
  };

  const handleResetFormChange = (field) => (event) => {
    setResetFormData({ ...resetFormData, [field]: event.target.value });
    validateResetField(field, event.target.value);
  };

  const validateField = (field, value) => {
    const newErrors = { ...errors };

    switch (field) {
      case "companyCode":
        newErrors.companyCode = !value ? "Company Code is required" : "";
        break;
      case "username":
        newErrors.username = !value ? "Username is required" : "";
        break;
      case "password":
        newErrors.password = value.length < 6 ? "Password must be at least 6 characters" : "";
        break;
      case "otp":
        newErrors.otp = value.length !== 6 ? "OTP must be 6 digits" : "";
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const validateResetField = (field, value) => {
    const newErrors = { ...errors };

    switch (field) {
      case "mobileNumber":
        const numberPattern = /^[0-9]{10}$/;
        newErrors.mobileNumber = !numberPattern.test(value) ? "Enter valid 10-digit mobile number" : "";
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    Object.keys(formData).forEach((field) => {
      validateField(field, formData[field]);
    });

    if (Object.values(errors).some((error) => error)) {
      setLoading(false);
      return;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("Login successful", formData);
    } catch (error) {
      console.error("Login failed", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    validateResetField("mobileNumber", resetFormData.mobileNumber);

    if (!resetFormData.country || errors.mobileNumber) {
      setLoading(false);
      return;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setShowOtpInput(true);
      startOtpTimer();
      console.log("OTP sent successfully");
    } catch (error) {
      console.error("Failed to send OTP", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    validateField("otp", otp);

    if (errors.otp) {
      setLoading(false);
      return;
    }

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log("OTP verified successfully");
      // Proceed with password reset logic
    } catch (error) {
      console.error("OTP verification failed", error);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      startOtpTimer();
      console.log("OTP resent successfully");
    } catch (error) {
      console.error("Failed to resend OTP", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container >
      <StyledPaper elevation={4}>
        <ImageSection>
          <Box
            sx={{
              position: "relative",
              zIndex: 1,
              color: "white",
              padding: "2rem",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <Typography variant="h3" fontWeight="bold" gutterBottom>
              Textile ERP
            </Typography>
            <Typography variant="h6">
              Streamline your textile business operations with our comprehensive ERP solution
            </Typography>
          </Box>
        </ImageSection>

        <FormSection>
          {!isResetForm ? (
            <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
              <Box sx={{ mb: 4, display: "flex", alignItems: "center" }}>
                <FaIndustry size={32} style={{ marginRight: "12px" }} />
                <Typography variant="h4" fontWeight="bold">
                  Login
                </Typography>
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Autocomplete
                    options={companyCodes}
                    getOptionLabel={(option) => `${option.label} - ${option.name}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company Code"
                        error={!!errors.companyCode}
                        helperText={errors.companyCode}
                        required
                        fullWidth
                      />
                    )}
                    onChange={(_, value) =>
                      setFormData({
                        ...formData,
                        companyCode: value ? value.label : ""
                      })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Username"
                    fullWidth
                    required
                    value={formData.username}
                    onChange={handleChange("username")}
                    error={!!errors.username}
                    helperText={errors.username}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Password"
                    fullWidth
                    required
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={handleChange("password")}
                    error={!!errors.password}
                    helperText={errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                            aria-label={showPassword ? "Hide password" : "Show password"}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    disabled={loading}
                    sx={{
                      mt: 2,
                      height: "48px",
                      background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                      boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)"
                    }}
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{ textAlign: "center", color: "text.secondary", mt: 2 }}
                  >
                    Forgot your password?{" "}
                    <Button
                      color="primary"
                      sx={{ textTransform: "none" }}
                      onClick={() => setIsResetForm(true)}
                    >
                      Reset here
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box component="form" onSubmit={showOtpInput ? handleOtpSubmit : handleResetSubmit} sx={{ width: "100%" }}>
              <Box sx={{ mb: 4, display: "flex", alignItems: "center" }}>
                <Button
                  startIcon={<FaArrowLeft />}
                  onClick={() => {
                    setIsResetForm(false);
                    setShowOtpInput(false);
                  }}
                  sx={{ mr: 2 }}
                >
                  Back
                </Button>
                <Typography variant="h4" fontWeight="bold">
                  Reset Password
                </Typography>
              </Box>

              {!showOtpInput ? (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={countries}
                      getOptionLabel={(option) => `${option.name} (${option.code})`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Country"
                          required
                          fullWidth
                        />
                      )}
                      value={resetFormData.country}
                      onChange={(_, value) =>
                        setResetFormData({
                          ...resetFormData,
                          country: value
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Mobile Number"
                      fullWidth
                      required
                      value={resetFormData.mobileNumber}
                      onChange={handleResetFormChange("mobileNumber")}
                      error={!!errors.mobileNumber}
                      helperText={errors.mobileNumber}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {resetFormData.country?.code || "+"}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      size="large"
                      disabled={loading}
                      sx={{
                        mt: 2,
                        height: "48px",
                        background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                        boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)"
                      }}
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : "Send OTP"}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Enter OTP"
                      fullWidth
                      required
                      value={otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        validateField("otp", e.target.value);
                      }}
                      error={!!errors.otp}
                      helperText={errors.otp}
                      inputProps={{ maxLength: 6 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography variant="body2" color="text.secondary">
                        {timer > 0 ? `Resend OTP in ${timer}s` : ""}
                      </Typography>
                      <Button
                        disabled={!canResend || loading}
                        onClick={handleResendOtp}
                        sx={{ textTransform: "none" }}
                      >
                        Resend OTP
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      size="large"
                      disabled={loading}
                      sx={{
                        mt: 2,
                        height: "48px",
                        background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                        boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)"
                      }}
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : "Verify OTP"}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </FormSection>
      </StyledPaper>
    </Container>
  );
};

export default LoginForm;