import axios from "axios";
import { HEADER_QUERY, HEADERS } from "../../../../../API/UrlAndPaths";
import store from "../../../../../Redux/store";
import {
  listPosNotes,
  setPageIndexForViewOrder,
  setPaginationForViewOrder,
  setTableLoading,
  storeHeldOrders,
  storeOrdersList,
} from "../Slice/viewOrdersBillingSlice";
import { setTableLoadingGlobal } from "../../../../../Redux/Dashboard/mainDashBoardSlice";
import {
  setBillLoading,
  setDialogBox,
  setErrorSnack,
  setOrderIdInPOS,
  setSingleOrderInfo,
  setSnackMsg,
  setSuccessSnack,
} from "../Slice/billingScreenSlice";
import { setFilterPopup } from "../Utilities/filterButtonSlice";
import { setAuthPassword } from "../newBillingSlice";

// export const listOrdersForBillingAPI = async (body) => {
//   store.dispatch(setTableLoading(true));
//   await axios
//     .post("pos/listPosOrders", body, HEADERS)
//     .then((res) => {
//       if (res.status === 200) {
//         store.dispatch(storeOrdersList(res.data));
//         store.dispatch(setTableLoading(false));
//         store.dispatch(setPaginationForViewOrder(false));
//         store.dispatch(setPageIndexForViewOrder(0));
//         store.dispatch(setFilterPopup(false));
//       }
//     })
//     .catch((err) => {
//       store.dispatch(storeOrdersList([]));
//       store.dispatch(setTableLoading(false));
//     });
// };
// export const listOrdersPaginationAPI = async (body) => {
//   console.log("body", body);
//   const isPagination = store.getState().viewOrdersBillingSlice.pagination;

//   store.dispatch(setTableLoadingGlobal(true));
//   await axios
//     .post("sales/viewPosBillOrders", body, HEADERS)
//     .then((res) => {
//       if (res.status === 200) {
//         const existingProductsList =
//           store.getState().viewOrdersBillingSlice.ordersList;
//         const updatedOrdersList = existingProductsList.concat(res.data);

//         // Remove duplicates from the updatedProductsList
//         const uniqueOrderList = updatedOrdersList.filter(
//           (product, index, self) =>
//             index === self.findIndex((p) => p._id === product._id)
//         );
//         store.dispatch(storeOrdersList(uniqueOrderList));
//         store.dispatch(setTableLoadingGlobal(false));
//         store.dispatch(setPaginationForViewOrder(false));
//       }
//     })
//     .catch((err) => {
//       err.response.status === 404 &&
//         store.dispatch(setPaginationForViewOrder(isPagination && "max"));
//       store.dispatch(setTableLoadingGlobal(false));
//     });
// };
// export const listHeldOrders = async (body) => {
//   store.dispatch(setTableLoading(true));
//   await axios
//     .post("pos/listPosOrders", body, HEADERS)
//     .then((res) => {
//       if (res.status === 200) {
//         store.dispatch(setTableLoading(false));
//         store.dispatch(storeHeldOrders(res.data));
//         store.dispatch(setFilterPopup(false));
//       }
//     })
//     .catch((err) => {
//       store.dispatch(setTableLoading(false));
//       store.dispatch(storeHeldOrders([]));
//     });
// };
// view bill orders api
// export const viewBillOrdersAPI = async (data) => {
//   try {
//     const response = await axios.post(
//       "sales/viewPosBillOrders",
//       data.body,
//       HEADERS
//     );
//     if (response.status === 200) {
//       store.dispatch(storeOrdersList(response.data));
//       store.dispatch(setTableLoading(false));
//       store.dispatch(setPaginationForViewOrder(false));
//       store.dispatch(setPageIndexForViewOrder(0));
//       store.dispatch(setFilterPopup(false));
//     //   data.setOrderHistory(response.data)
//       if (data.body.isHeldOrder) {
//         store.dispatch(storeHeldOrders(response.data));
//       } else {
//         store.dispatch(storeOrdersList(response.data));
//       }
//     }
//   } catch (error) {
//     if (data.body.isHeldOrder) {
//       store.dispatch(storeHeldOrders([]));
//     } else {
//       store.dispatch(storeOrdersList([]));
//     }
//     // data.setOrderHistory([]);
//     store.dispatch(setTableLoading(false));
//     // console.log("err", response);
//   }
// };
// ------------ new view bill orders api ------------

// View bill orders api with fixed logic
export const viewBillOrdersAPI = async (data) => {
  try {
    const response = await axios.post(
      "sales/viewPosBillOrders",
      data.body,
      HEADERS
    );

    if (response.status === 200) {
      store.dispatch(setTableLoading(false));
      store.dispatch(setPaginationForViewOrder(false));
      store.dispatch(setPageIndexForViewOrder(0));
      store.dispatch(setFilterPopup(false));
      // Check if we're handling held orders or view orders
      if (data.body.type === 1) {
        // Held orders
        store.dispatch(storeHeldOrders(response.data));
      } else if (data.body.type === 2) {
        // View orders
        store.dispatch(storeOrdersList(response.data));
      }
    }
  } catch (error) {
    store.dispatch(setTableLoading(false));
    // Only clear the respective list based on the type
    if (data.body.type === 1) {
      // Held orders
      store.dispatch(storeHeldOrders([]));
    } else if (data.body.type === 2) {
      // View orders
      store.dispatch(storeOrdersList([]));
    }
  }
};

// List orders for billing API with fixed logic
export const listOrdersForBillingAPI = async (body) => {
  store.dispatch(setTableLoading(true));

  try {
    const response = await axios.post("pos/listPosOrders", body, HEADERS);

    if (response.status === 200) {
      // Only update orders list for view orders
      if (body.type === 2) {
        store.dispatch(storeOrdersList(response.data));
      }
      store.dispatch(setTableLoading(false));
      store.dispatch(setPaginationForViewOrder(false));
      store.dispatch(setPageIndexForViewOrder(0));
      store.dispatch(setFilterPopup(false));
    }
  } catch (err) {
    store.dispatch(setTableLoading(false));
    // Only clear orders list for view orders
    if (body.type === 2) {
      store.dispatch(storeOrdersList([]));
    }
  }
};

// List held orders with fixed logic
export const listHeldOrders = async (body) => {
  store.dispatch(setTableLoading(true));

  try {
    const response = await axios.post("pos/listPosOrders", body, HEADERS);

    if (response.status === 200) {
      // Only update held orders for type 1
      if (body.type === 1) {
        store.dispatch(storeHeldOrders(response.data));
      }
      store.dispatch(setTableLoading(false));
      store.dispatch(setFilterPopup(false));
    }
  } catch (err) {
    store.dispatch(setTableLoading(false));
    // Only clear held orders for type 1
    if (body.type === 1) {
      store.dispatch(storeHeldOrders([]));
    }
  }
};

// List orders pagination API with fixed logic
export const listOrdersPaginationAPI = async (body) => {
  const isPagination = store.getState().viewOrdersBillingSlice.pagination;
  store.dispatch(setTableLoadingGlobal(true));

  try {
    const response = await axios.post("sales/viewPosBillOrders", body, HEADERS);

    if (response.status === 200) {
      // Only handle pagination for view orders (type 2)
      if (body.type === 2) {
        const existingProductsList =
          store.getState().viewOrdersBillingSlice.ordersList;
        const updatedOrdersList = existingProductsList.concat(response.data);

        // Remove duplicates from the updatedProductsList
        const uniqueOrderList = updatedOrdersList.filter(
          (product, index, self) =>
            index === self.findIndex((p) => p._id === product._id)
        );

        store.dispatch(storeOrdersList(uniqueOrderList));
      }
      store.dispatch(setTableLoadingGlobal(false));
      store.dispatch(setPaginationForViewOrder(false));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      store.dispatch(setPaginationForViewOrder(isPagination && "max"));
    }
    store.dispatch(setTableLoadingGlobal(false));
  }
};

// -------------end of view bill orders api -------------

export const deleteBookingOrder = async (body, clearFn) => {
  store.dispatch(setBillLoading(true));
  await axios
    .post("pos/deleteBookingOrder", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        clearFn();
        store.dispatch(setOrderIdInPOS(null));
        store.dispatch(setSingleOrderInfo(null));
        store.dispatch(setBillLoading(false));
        store.dispatch(setSnackMsg("Booking Cancelled"));
        store.dispatch(setSuccessSnack(true));
        store.dispatch(setAuthPassword(""));
        store.dispatch(setDialogBox(null));
      }
    })
    .catch((err) => {
      let message =
        err.response.status === 500
          ? "Internal Server Error !!"
          : err.response.data || "Network Error !!";
      store.dispatch(setSnackMsg(message));
      store.dispatch(setBillLoading(false));
      store.dispatch(setErrorSnack(true));
    });
};
export const cancelOngoingOrderApiCall = async (body, clearFn) => {
  store.dispatch(setBillLoading(true));

  await axios
    .post("pos/cancelOngoingOrders", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(setAuthPassword(""));
        store.dispatch(setDialogBox(null));
        clearFn();
        store.dispatch(setOrderIdInPOS(null));
        store.dispatch(setSingleOrderInfo(null));
        store.dispatch(setBillLoading(false));
        store.dispatch(setSnackMsg("Order Cancelled"));
        store.dispatch(setSuccessSnack(true));
      }
    })
    .catch((err) => {
      let message =
        err.response.status === 500
          ? "Internal Server Error !!"
          : err.response.data || "Network Error !!";
      store.dispatch(setSnackMsg(message));
      store.dispatch(setBillLoading(false));
      store.dispatch(setErrorSnack(true));
    });
};

export const cancelHeldOrder = async (data) => {
  try {
    const response = await axios.post(
      "sales/cancelOngoingOrders",
      data.body,
      HEADERS
    );
    if (response.status === 200) {
      const payload = {
        index: 0,
        search: "",
        isHeldOrder: true,
        type: 1,
        branchId: data.body.branchId,
      };
      viewBillOrdersAPI({ body: payload });
      // console.log("response", response);
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const listPosNotesApiCall = (body) => {
  axios
    .post("pos/listposNotes", body, HEADERS)
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(listPosNotes({ listNotes: res.data }));
        // setNotes(res.data);
      }
    })
    .catch((err) => {
      console.log("error", err);
      store.dispatch(listPosNotes({ listNotes: undefined }));
      //  setNotes([]);
    });
};
export const deleteNoteApiCall = (body, viewNotes) => {
  store.dispatch(setBillLoading(true));
  axios
    .delete("pos/deletePosNoteById", { headers: HEADER_QUERY, data: body })
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(setBillLoading(false));
        viewNotes();
      }
    })
    .catch((err) => {
      store.dispatch(setBillLoading(false));
      console.log("error", err);
    });
};
