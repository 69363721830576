import React, { useEffect, useMemo, useRef, useState } from "react";
import { Home, Trash2, FilePenLine, Check, Loader2, Info } from "lucide-react";
import "./generalAlterationStyle.css";
import { Box, CircularProgress, TextField } from "@mui/material";
import StyledInputBox from "./components/StyledInputBox";
import StyledAutocomplete from "./components/StyledAutocomplete";
import { useNavigate } from "react-router-dom";
import {
  addAlterationAPI,
  getActiveShift,
  getAlterationOrderNoAPI,
  listCustomerAPI,
  listProductAPI,
  listProductColorAPI,
  listTaxesAPI,
  listWorkOrderAPI,
} from "../GeneralAPI";
import StyledSnackbar from "./components/StyledSnackbar";
import { useSelector } from "react-redux";
import { decodeJWTFromLocalStorage } from "../../../../../generalFunctions";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

function getCurrentTime() {
  const now = new Date();

  // Pad the hours, minutes, and seconds with leading zeros if needed
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}

const GeneralAlterationCreate = () => {
  const navigate = useNavigate();

  // get login employee details
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const TokenDecode = decodeJWTFromLocalStorage();

  // get branch details

  const [orderItems, setOrderItems] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);

  const initialFormData = {
    customer: "",
    barcode: "",
    product: "",
    productColor: "",
    qty: "",
    itemRate: "",
    stichRate: "",
    taxes: [],
    amount: "",
  };
  const [commonField, setCommonField] = useState({
    orderNo: "",
    orderDate: getCurrentDate(),
    deliveryDate: getCurrentDate(),
    customer: "",
    workOrder: "",
    oldOrder: false,
  });
  const [formData, setFormData] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState({});
  const [isLoading, setLoading] = useState(false);

  const validateField = (name, value) => {
    if (name === "oldOrder") return { error: false, message: "" };
    if (name === "netAmount") return { error: false, message: "" };
    if (name === "taxAmount") return { error: false, message: "" };
    if (name === "shift") return { error: false, message: "" };
    if (name === "taxes") return { error: false, message: "" };
    if (name === "barcode") return { error: false, message: "" };

    if (name === "workOrder") {
      if (commonField.oldOrder && name === "workOrder" && !value) {
        return {
          error: true,
          message: "Work Order is required for new orders",
        };
      }
      return { error: false, message: "" };
    }

    if (typeof value === "string" && value.trim() === "") {
      return { error: true, message: "This field is required" };
    }
    if (typeof value === "number" && isNaN(value)) {
      return { error: true, message: "This field must be a number" };
    }
    return { error: false, message: "" };
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setCommonField((prevData) => {
      if (!prevData.hasOwnProperty(name)) return prevData;

      const updatedData = {
        ...prevData,
        [name]: name === "oldOrder" ? !prevData.oldOrder : inputValue,
      };

      if (name === "oldOrder") {
        updatedData.workOrder = "";
      }

      return updatedData;
    });

    setFormData((prevData) => {
      let updatedData = { ...prevData };

      // Only update the key if it exists in the state
      if (prevData.hasOwnProperty(name)) {
        updatedData[name] = inputValue;
      }

      // Update itemRate only if the product changes and key "product" exists
      if (name === "product" && prevData.hasOwnProperty("itemRate")) {
        updatedData.itemRate = inputValue?.calculatedPrice || 0;
      }

      return updatedData;
    });

    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      [name]: { error: false, message: "" },
    }));
  };

  const handleAddItem = () => {
    const allField = { ...formData, ...commonField };
    const newErrorMessage = Object.keys(allField).reduce((acc, key) => {
      acc[key] = validateField(key, allField[key]);
      return acc;
    }, {});

    setErrorMessage(newErrorMessage);

    if (Object.values(newErrorMessage).some((field) => field.error)) {
      return; // Don't proceed if there are errors
    }

    const newItem = {
      ...formData,
      id: editingItemId || Math.random().toString(36).substr(2, 9),
      qty: parseFloat(formData.qty) || 0,
      itemRate: parseFloat(formData.itemRate) || 0,
      stichRate: parseFloat(formData.stichRate) || 0,
      amount: parseFloat(formData.amount) || 0,
    };

    setOrderItems((prevItems) =>
      editingItemId
        ? prevItems.map((item) => (item.id === editingItemId ? newItem : item))
        : [...prevItems, newItem]
    );

    setEditingItemId(null);
    setFormData((prevData) => ({
      ...prevData,
      product: "",
      productColor: "",
      qty: "",
      itemRate: "",
      stichRate: "",
      taxes: "",
      amount: "",
    }));
  };

  const handlePayment = () => {
    setLoading(true);
    addAlterationAPI({ body: orderItems, isLoading: setLoading });
  };

  const handlePlaceOrder = () => {
    const mapOrderItem = (res) => ({
      itemId: res?.product?._id ?? null,
      customerId: commonField?.customer?._id ?? null,
      color: res?.productColor?._id ?? null,
      orderQuantity: res?.qty ?? null,
      itemRate: res?.itemRate ?? 0,
      stitchRate: res?.stichRate ?? 0,
      amount: res?.amount ?? 0,
      taxes: res?.taxes ?? 0,
      taxAmount: res?.taxAmount ?? 0,
      netAmount: res?.netAmount ?? 0,
    });

    const items = orderItems.map(mapOrderItem);

    const payload = {
      orderDate: `${commonField.orderDate} ${getCurrentTime()}`,
      deliveryDate: `${commonField.deliveryDate} 23:59:59`,
      shiftId: commonField?.shift?._id,
      employeeId: loginResponse?.data?._id,
      customerId: commonField?.customer?._id,
      branchId: loginResponse?.branchPk,
      oldorder: commonField.oldOrder,
      total: totals.amount,
      returnOrderReferenceId: commonField?.workOrder?._id ?? null,
      items,
    };

    addAlterationAPI({
      body: payload,
      isLoading: setLoading,
      handleClear,
      payload: { branchId: loginResponse?.branchPk },
      setCommonField,
    });
  };

  const handleClear = () => {
    setFormData((prev) => ({
      ...prev,
      customer: "",
      barcode: "",
      product: "",
      productColor: "",
      qty: "",
      itemRate: "",
      stichRate: "",
      taxes: "",
      amount: "",
    }));
    setCommonField((prev) => ({
      ...prev,
      orderNo: prev.orderNo,
      orderDate: getCurrentDate(),
      deliveryDate: getCurrentDate(),
      customer: "",
      workOrder: "",
      oldOrder: false,
    }));
    setOrderItems([]);
    setEditingItemId(null);
    setErrorMessage({});
  };

  const handleDelete = (data) => {
    setOrderItems((prevItems) =>
      prevItems.filter((item) => item.id !== data.id)
    );
    if (editingItemId === data.id) {
      setEditingItemId(null);
      setFormData({
        ...formData,
        product: "",
        productColor: "",
        type: "",
        model: "",
        qty: "",
        itemRate: "",
        stichRate: "",
        taxes: "",
        amount: "",
      });
    }
  };

  // Calculate totals
  const totalAmount = useMemo(() => {
    if (!formData?.product) return 0;
    // Calculate the netAmount
    const netAmount =
      (Number(formData.product.calculatedPrice) + Number(formData.stichRate)) *
      formData.qty;

    let taxAmount = 0;
    let totalAmount = netAmount;

    if (formData.taxes?.length > 0) {
      // Filter out the exclusive and inclusive taxes
      const exclusiveTaxes = formData.taxes.filter((tax) => !tax.includedPrice);
      const inclusiveTaxes = formData.taxes.filter((tax) => tax.includedPrice);

      // Calculate the inclusive taxes
      if (inclusiveTaxes.length > 0) {
        const inclusiveTaxAmount = inclusiveTaxes.reduce(
          (acc, tax) => acc + (netAmount * tax?.amount) / 100,
          0
        );
        taxAmount += inclusiveTaxAmount;
      }
      // Calculate the exclusive taxes
      if (exclusiveTaxes.length > 0) {
        const exclusiveTaxAmount = exclusiveTaxes.reduce(
          (acc, tax) => acc + (netAmount * tax?.amount) / 100,
          0
        );
        taxAmount += exclusiveTaxAmount;
        totalAmount += exclusiveTaxAmount;
      }
    }

    // Update formData with netAmount, taxAmount, and amount
    setFormData((prev) => ({
      ...prev,
      netAmount,
      taxAmount,
      amount: totalAmount,
    }));

    return totalAmount;
  }, [formData.taxes, formData.qty, formData.stichRate, formData.product]);

  // Calculate totals
  const totals = useMemo(() => {
    return orderItems.reduce(
      (acc, item) => {
        acc.qty += item.qty;
        acc.itemRate += item.itemRate;
        acc.stichRate += item.stichRate;
        acc.amount += item.amount;
        return acc;
      },
      { qty: 0, itemRate: 0, stichRate: 0, amount: 0 }
    );
  }, [orderItems]);

  const handleEdit = (item) => {
    setErrorMessage({});
    setFormData(item);
    setEditingItemId(item.id);
  };

  const handleBack = () => {
    // Navigate to home
    navigate("/userdashboard/pos/general/alteration");
  };

  // initial fetch apis
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialApiResult, setInitialApiResult] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setInitialLoading(true);
      const currentDateTime = new Date()
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      const body = {
        branchId: loginResponse?.branchPk,
        curTime: currentDateTime,
      };

      const apiCalls = [
        { api: getAlterationOrderNoAPI, key: "orderNo", responseKey: "data" },
        { api: listWorkOrderAPI, key: "listWorkOrder" },
        { api: listProductColorAPI, key: "listProductColor" },
        { api: listProductAPI, key: "listProduct" },
        { api: listCustomerAPI, key: "listCustomer" },
        { api: listTaxesAPI, key: "listTaxes" },
        { api: getActiveShift, key: "getShift" },
      ];

      try {
        const results = await Promise.all(
          apiCalls.map(({ api, key, responseKey = "data" }) =>
            api({ body }).then((response) => ({
              [key]: response.status === 200 ? response[responseKey] : null,
            }))
          )
        );

        const combinedResults = Object.assign({}, ...results);

        setCommonField((prev) => ({
          ...prev,
          ...(combinedResults.orderNo && { orderNo: combinedResults.orderNo }),
          ...(combinedResults.getShift && { shift: combinedResults.getShift }),
        }));

        setInitialApiResult(combinedResults);
      } catch (err) {
        // click more details to see the error open console
        console.error("An error occurred during API calls: ", err);
        setError(
          `Failed to fetch data from APIs. Please try again later or check the console for more details.\n\n` +
          `Error details: ${err.message || 'Unknown error occurred.'}`
        );
      } finally {
        setInitialLoading(false);
      }
    };

    fetchData();
  }, []);

  const [filteredTaxOptions, setFilteredTaxOptions] = useState(
    initialApiResult?.listTaxes
  );

  useEffect(() => {
    // Update filteredTaxOptions when formData.taxes changes

    if (formData.taxes?.length > 0) {
      const selectedTax = formData.taxes[formData.taxes.length - 1];

      const matchedIncludedPrice = selectedTax.includedPrice;

      const updatedOptions = initialApiResult?.listTaxes.filter(
        (tax) => tax.includedPrice === matchedIncludedPrice
      );

      setFilteredTaxOptions(updatedOptions);
    } else {
      setFilteredTaxOptions(initialApiResult?.listTaxes);
    }
  }, [formData.taxes, initialApiResult]);

  if (initialLoading) {
    return (
      <div className="in-spn-loader-container">
        <Loader2 className="in-spn-loader-icon" />
        <p className="in-spn-loader-text">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="in-spn-alert-container">

        {/* error alert  */}

      <div className="in-spn-alert in-spn-alert-error ">
        <div className="in-spn-alert-error-content">
          <Info className="in-spn-alert-icon" />
          <h2 className="in-spn-alert-title">Error</h2>
          </div>
          <p className="in-spn-alert-description">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="aof-wrapper">
      <div className="aof-container">
        <div className="aof-header">
          <div className="aof-left-side">
            <Home
              className="aof-home-icon"
              onClick={() => handleBack()}
              style={{ cursor: "pointer" }}
            />
            <h3 style={{ color: "#4caf50" }}>ALTERATION</h3>
          </div>
          <div className="aof-header-buttons">
            <strong>Old Order</strong>
            <label className="aof-switch">
              <input
                type="checkbox"
                checked={commonField.oldOrder}
                onChange={(e) =>
                  handleInputChange({
                    target: { value: !commonField.oldOrder, name: "oldOrder" },
                  })
                }
              />

              <span className="aof-slider aof-round"></span>
            </label>
            <button
              disabled={orderItems?.length === 0}
              className={`aof-btn aof-btn-payment ${
                isLoading && "p-loading-btn"
              } ${orderItems?.length === 0 && "aof-btn-disabled"}`}
              onClick={handlePayment}
            >
              {isLoading ? (
                <CircularProgress size={13} sx={{ color: "#ffffff" }} />
              ) : (
                "Payment"
              )}
            </button>
            <button
              className={`aof-btn aof-btn-place-order ${
                orderItems?.length === 0 && "aof-btn-disabled"
              }`}
              onClick={handlePlaceOrder}
              disabled={orderItems?.length === 0}
            >
              Place Order
            </button>
            <button className="aof-btn aof-btn-clear" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="aof-form-grid">
          {/* order no input  */}

          <StyledInputBox
            error={errorMessage?.orderNo?.error}
            errorMessage={errorMessage?.orderNo?.message}
            label={"Order No"}
            name={"orderNo"}
            onChange={handleInputChange}
            value={commonField?.orderNo?.prefix}
            disabled
            id={"outlined-size-small"}
          />

          {/* date input  */}
          <StyledInputBox
            error={errorMessage?.orderDate?.error}
            errorMessage={errorMessage?.orderDate?.message}
            label={"Order Date"}
            name={"orderDate"}
            onChange={handleInputChange}
            value={commonField.orderDate}
            id={"outlined-size-small"}
            type={"date"}
          />

          {/* work order  */}

          {commonField?.oldOrder && (
            <StyledAutocomplete
              label="Work Order"
              id="workOrder"
              name="workOrder"
              value={commonField.workOrder}
              onChange={handleInputChange}
              error={errorMessage?.workOrder?.error}
              errorMessage={errorMessage?.workOrder?.message}
              options={initialApiResult?.listWorkOrder}
              optionLabelKey="sequence"
            />
          )}

          {/* barcode  */}
          <StyledInputBox
            error={errorMessage?.barcode?.error}
            errorMessage={errorMessage?.barcode?.message}
            label={"Barcode"}
            name={"barcode"}
            onChange={handleInputChange}
            value={formData.barcode}
            id={"outlined-size-small"}
          />

          {/* product  */}

          <StyledAutocomplete
            label="Product"
            id="product"
            name="product"
            value={formData.product}
            onChange={handleInputChange}
            error={errorMessage?.product?.error}
            errorMessage={errorMessage?.product?.message}
            infoMessage={`Available Stock ${Number(
              formData?.product?.stock || ""
            ).toFixed(2)} `}
            options={initialApiResult?.listProduct}
            optionLabelKey="productName"
          />

          {/* productColor */}

          <StyledAutocomplete
            label="Product Color"
            id="productColor"
            name="productColor"
            value={formData.productColor}
            onChange={handleInputChange}
            error={errorMessage?.productColor?.error}
            errorMessage={errorMessage?.productColor?.message}
            options={initialApiResult?.listProductColor}
            optionLabelKey={"colorName"}
          />

          {/* deliveryDate */}
          <StyledInputBox
            error={errorMessage?.deliveryDate?.error}
            errorMessage={errorMessage?.deliveryDate?.message}
            label={"DeliveryDate"}
            name={"deliveryDate"}
            onChange={handleInputChange}
            value={commonField.deliveryDate}
            id={"outlined-size-small"}
            type={"date"}
          />

          {/* customer */}

          <StyledAutocomplete
            label="Customer"
            id="customer"
            name="customer"
            value={commonField.customer}
            onChange={handleInputChange}
            error={errorMessage?.customer?.error}
            errorMessage={errorMessage?.customer?.message}
            options={initialApiResult?.listCustomer}
            optionLabelKey="name"
            optionValueKey={"mobileNo"}
          />

          {/* quantity  */}
          <StyledInputBox
            error={errorMessage?.qty?.error}
            errorMessage={errorMessage?.qty?.message}
            label={"Qty"}
            name={"qty"}
            onChange={handleInputChange}
            value={formData.qty}
            id={"outlined-size-small"}
            type={"number"}
          />
          {/* item rate  */}
          <StyledInputBox
            disabled
            error={false}
            errorMessage={errorMessage?.itemRate?.message}
            label={"Item Rate"}
            name={"itemRate"}
            onChange={handleInputChange}
            value={formData?.itemRate}
            id={"outlined-size-small"}
            type={"number"}
          />

          {/* stich Rate  */}
          <StyledInputBox
            error={errorMessage?.stichRate?.error}
            errorMessage={errorMessage?.stichRate?.message}
            label={"Stich Rate"}
            name={"stichRate"}
            onChange={handleInputChange}
            value={formData.stichRate}
            id={"outlined-size-small"}
            type={"number"}
          />

          {/* taxes */}
          <StyledAutocomplete
            label="Taxes"
            id="taxes"
            name="taxes"
            value={formData.taxes}
            onChange={handleInputChange}
            error={errorMessage?.taxes?.error}
            errorMessage={errorMessage?.taxes?.message}
            options={filteredTaxOptions}
            optionLabelKey="taxName"
            multiple={true}
          />

          {/* amount  */}
          <StyledInputBox
            disabled
            error={false}
            errorMessage={errorMessage?.amount?.message}
            label={"Amount"}
            name={"amount"}
            onChange={handleInputChange}
            value={totalAmount || 0}
            id={"outlined-size-small"}
            type={"number"}
          />
          {Array.from({ length: !commonField.oldOrder ? 5 : 4 }).map(
            (_, index) => (
              <div key={index}></div>
            )
          )}
          <button
            className="aof-btn aof-btn-add"
            style={{ marginLeft: 0 }}
            onClick={handleAddItem}
          >
            {editingItemId ? <Check /> : "ADD"}
          </button>
        </div>

        <div className="aof-table-wrapper">
          <table className="aof-order-table">
            <thead>
              <tr>
                <th>CUSTOMER</th>
                <th>PRODUCT</th>
                <th>COLOR</th>
                <th>QUANTITY</th>
                <th>ITEM RATE</th>
                <th>STICH RATE</th>
                <th>TAX</th>
                <th>AMOUNT</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {orderItems.map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? "pos-b2" : ""}>
                  <td>{commonField.customer?.name || ""}</td>
                  <td>{item.product?.productName || ""}</td>
                  <td>{item.productColor?.colorName || ""}</td>
                  <td>{item.qty}</td>
                  <td>{item.itemRate}</td>
                  <td>{item.stichRate}</td>
                  <td>
                    {Array.isArray(item.taxes)
                      ? item.taxes.map((tax) => tax.taxName).join(", ")
                      : ""}
                  </td>
                  <td>{item.amount}</td>
                  {editingItemId !== item.id ? (
                    <td className="pos-tb-action">
                      <button
                        className="aof-btn aof-btn-edit"
                        onClick={() => handleEdit(item)}
                      >
                        <FilePenLine />
                      </button>
                      <button
                        className="aof-btn aof-btn-delete"
                        onClick={() => handleDelete(item)}
                      >
                        <Trash2 />
                      </button>
                    </td>
                  ) : (
                    <td className="pos-tb-action">
                      {" "}
                      <Box sx={{ display: "flex", p: 1 }}>
                        <CircularProgress size={20} />
                      </Box>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="3">Total</td>
                <td>{totals.qty.toFixed(2)}</td>
                <td>{totals.itemRate.toFixed(2)}</td>
                <td>{totals.stichRate.toFixed(2)}</td>
                <td></td>
                <td>{totals.amount.toFixed(2)}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <StyledSnackbar />
    </div>
  );
};

export default GeneralAlterationCreate;
